import $ from 'jquery';
import SwiperCore, { Navigation, Pagination, EffectFade } from 'swiper/core';
import Swiper from 'swiper';
import LazyImages from '../components/lazy-images';
import SingleProductFullscreenSlider from './single-product-fullscreen-slider';
export default class SingleProductSlider {
    static $slider = $('.js__single-product-slider');
    static $slides = SingleProductSlider.$slider.find('.swiper-slide');
    static swiper;
    static options = {
        effect: 'fade',
        fadeEffect: {
            crossFade: true,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        loop: true,
    };
    static init() {
        this.$slider = $('.js__single-product-slider');
        this.$slides = SingleProductSlider.$slider.find('.swiper-slide');
        if (!this.$slider.length) {
            return;
        }
        this.$slides.remove();
        SwiperCore.use([Navigation, Pagination, EffectFade]);
        this.swiper = new Swiper(this.$slider.get(0), this.options);
        this.swiper.on('slideChange', () => {
            if (SingleProductFullscreenSlider.isFullscreenExist()) {
                SingleProductFullscreenSlider.slideTo(this.swiper.activeIndex);
            }
        });
    }
    static showAllSlides() {
        this.showSlides(this.$slides);
    }
    static isSliderExist() {
        return this.$slider.length > 0;
    }
    static getActiveSlide() {
        return this.swiper.activeIndex;
    }
    static showSlidesByVariantCode(variantCode) {
        const $slidesByVariantCode = this.$slides.filter((index, slide) => String($(slide).data('variant-code')) === variantCode);
        this.showSlides($slidesByVariantCode);
    }
    static showSlides($slides) {
        const $paginationBullets = this.$slider.find('.swiper-pagination');
        $paginationBullets.addClass('swiper-pagination-no-transition');
        this.swiper.removeAllSlides();
        this.swiper.appendSlide($slides.toArray());
        this.swiper.slideToLoop(0);
        $(this.swiper.pagination.el)
            .add(this.swiper.navigation.prevEl)
            .add(this.swiper.navigation.nextEl)
            .toggleClass('invisible', $slides.length === 1);
        LazyImages.update();
        setTimeout(() => {
            $paginationBullets.removeClass('swiper-pagination-no-transition');
        }, 1000);
    }
}
